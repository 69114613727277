@import "./assets/fonts/fonts.css";
/* slick-carousel default styles */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.App {
  text-align: center;
}

.menu-item {
  border: 1px solid transparent;
  /* Add an invisible border */
  border-radius: 56px;
  transition: border-color 0.3s;
}

.menu-item:hover {
  border: 1px solid #555;
 
}

/* second version styles  */

.h1 {
  font-size: 56px;
  line-height: 64px;
  letter-spacing: -2px;
  font-style: normal;
  font-family: "ClashGrotesk";
  background: var(--Gradient,
      linear-gradient(116deg, #0171c0 16.59%, #038c91 50.33%, #06b053 83.41%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 500;
}

.h1-black {
  color: var(--Txt-Primary, #161c2d);
  font-family: "ClashGrotesk";
  font-size: 56px;
  font-style: normal;
  font-weight: 500;
  line-height: 64px;
  letter-spacing: -2px;
}


.sub3 {
  color: var(--Txt-secondary, #555);
  font-family: Inter;
  font-size: 26px;
  font-style: normal;
  font-weight: 300;
  line-height: 36px;
  letter-spacing: -1px;
}

.para1 {
  color: var(--Txt-secondary, #555);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
}

.button1 {
  border-radius: 100px;
  background: var(--Txt-Primary, #161c2d);
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  display: flex;
  padding: 24px 56px;
  align-items: center;
  gap: 8px;
}

.button2 {
  border-radius: 100px;
  border: 1px solid var(--Txt-Primary, #161c2d);
  background: var(--White, #fff);
  color: var(--Txt-Primary, #161c2d);
  text-align: center;

  /* lable1 */
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 133.333% */
  display: flex;
  padding: 24px 56px;
  align-items: center;
  gap: 8px;
}

.sub1 {
  color: var(--Txt-Primary, #161c2d);
  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: -1px;
}

.sub2 {
  color: #161c2d;
  font-family: Inter;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: -1px;
}

.label1 {
  color: var(--White, #fff);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.label3 {
  color: var(--White, #FFF);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.5px;
}

.para2 {
  color: var(--Txt-Primary, #161c2d);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
}

.header-btn {
  padding: 8px 24px;
  font-size: 14px;
  margin-right: 10px;
}

.button1:hover {
  background-color: #3d4049;
}

.button2:hover {
  background-color: #e5e5e5;
}

.scroll-arrow rect:hover {
  fill: #161c2d;
}

.h2-mobile {
  font-family: "ClashGrotesk";
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: -2px;
  background: var(--Gradient,
      linear-gradient(116deg, #0171c0 16.59%, #038c91 50.33%, #06b053 83.41%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.img-dropshadow {
  -webkit-filter: drop-shadow(3px 3px 3px #222);
  filter: drop-shadow(3px 3px 3px #222);
}


@media screen and (max-width: 767px) {

  .button1,
  .button2 {
    padding: 16px 30px;
  }

  .h1,
  .h1-black {
    /* font-size: 44px; */
    line-height: 44px;
    font-size: 36px;
  }

  .banner-slide-wrapper .slick-dots {
    display: none !important;
  }

  .terms-logo {
    margin-left: 0.75rem;
  }

  .terms-logo-wrapper {
    margin-left: 0 !important;
    justify-content: left !important;
  }
}

.slick-track,
.slick-list {
  -webkit-perspective: 2000;
  -webkit-backface-visibility: hidden;
}

.how-it-works-image {
  opacity: 0;
  filter: blur(5px);
  transform: translateX(-100%);
  transition: all 1s;
}

.show-image {
  opacity: 1;
  filter: blur(0);
  transform: translateX(0);
}

.how-it-works-imagewrapper div:nth-child(1) .how-it-works-image {
  transition-delay: 200ms;
}

.how-it-works-imagewrapper div:nth-child(2) .how-it-works-image {
  transition-delay: 400ms;
}

.how-it-works-imagewrapper div:nth-child(3) .how-it-works-image {
  transition-delay: 600ms;
}


@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(-50px);
    /* optional for a subtle upward motion */
    transition-delay: 600ms;
  }

  to {
    opacity: 1;
    transform: translateX(0);

  }
}

@keyframes fadeInTop {
  from {
    opacity: 0;
    transform: translateY(50px);
    /* for a subtle upward motion */
    transition-delay: 600ms;
  }

  to {
    opacity: 1;
    transform: translateY(0);

  }
}

.fade-in {
  animation: fadeIn 1s ease-out forwards;
}

.fade-in-top {
  animation: fadeInTop 1s ease-out forwards;
}

.text-container {
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
}

.text-container.fade-out {
  opacity: 0;
}


.logo {
  transition: all 0.3s ease-in-out;

}

.banner-slide {
  display: flex !important;
}

.banner-slide-wrapper .slick-dots {
  bottom: 15%
}


.banner-slide-wrapper .slick-prev {
  left: 5%;
  cursor: pointer;
  display: none
}

.banner-slide-wrapper .slick-prev:before {
  color: gray;
  display: none
}

.banner-slide-wrapper .slick-next {
  right: 5%;
  cursor: pointer;
  width: 30px;
  height: 30px;
}

.banner-slide-wrapper .slick-next:before {
  color: gray;
  font-size: 30px;

}

@media screen and (min-width: 992px) and (max-width:1246px) {
  .menu-item {
    font-size: 12px !important;
    padding: 5px !important;
  }

  .button1,
  .button2 {
    padding: 12px 20px !important;
    font-size: 16px !important;

  }

  .header-btn {
    padding: 2px 20px !important;
    font-size: 12px !important;

  }


}


@media screen and (min-width: 768px) and (max-width:1024px) {
  .banner-image-ipad {
    width: 60%;
    margin: 0 auto
  }

  .banner-h1 {
    padding-top: 3% !important;
    padding-bottom: 0 !important;
  }

  .p-frame {
    padding-left: 70px;
    padding-right: 70px;
  }

}

@media screen and (min-width: 768px) and (max-width:1023px){
  .banner-text-wrapper{
    text-align: center;
  }
  .banner-button-wrapper{
    justify-content: center;
  }
}

.container-wrapper{
  max-width: 1440px;
  margin: 0 auto;
}

.banner-slide-wrapper  .slick-track
{
    display: flex !important;
}

.banner-slide-wrapper .slick-slide
{
    height: inherit !important;
}

.banner-slide-wrapper .slick-slide > div { height: 100%; }