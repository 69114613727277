html {
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@import url("https://fonts.googleapis.com/css?family=Josefin+Sans");
@import "https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap";

code {
  font-family: Inter, Raleway, sans-serif, source-code-pro, Menlo, Monaco,
    Consolas, "Courier New", monospace;
}
